import { Button, Link, ThemeType, useTheme } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RouteConstants from "../../../constants/RouteConstants";
import UserAllotmentConstants from "../../../constants/UserAllotmentConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { EventTimingDTO, EventTimingTypeDTO } from "../../../types/EventTiming";
import { GameRequestCountDTO } from "../../../types/Game";
import { useTimeUntil } from "../../../util/TimeUtil";
import TimeRemaining from "./TimeRemaining";
import { RequestTypeCountDTO } from "../../../types/RequestType";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 0;
  @media (max-width: 767px) {
    margin-top: 0.5rem;
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: row;
  }
  @media (min-width: 768px) {
    flex-direction: column;
  }
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const TimeHolder = styled.div`
  @media (max-width: 1024px) {
    margin-left: 0.5rem;
  }
`;

const CountInfo = styled.div`
  color: ${(props) => props.color};
  font-size: 0.625rem;
  font-style: italic;
`;

const APPROVED = "approved";
const DENIED = "denied";
const PENDING = "pending";

const getColor = (key: string, Theme: ThemeType) => {
  switch (key) {
    case APPROVED:
      return Theme.palette.coreColorsOnSurfaceGreen;
    case PENDING:
      return Theme.palette.coreColorsOnSurfaceBlue;
    case DENIED:
      return Theme.palette.coreColorsOnSurfaceRed;
    default:
      return Theme.palette.coreColorsBlack;
  }
};

const hasCount = (value: number) => value && value > 0;

const getStatusId = (category: string) => {
  switch (category) {
    case PENDING:
      return "1,2";
    case APPROVED:
      return "3";
    case DENIED:
      return "4";
    default:
      return "";
  }
};

const Count = ({ dataKey, data, fullWord, category, gamePk }) => {
  const { Theme } = useTheme();
  const color = getColor(dataKey, Theme);
  const value = data ? data[dataKey] : 0;
  const statusId = getStatusId(dataKey);
  const search = `?category=${category}&status=${statusId}&gamePk=${gamePk}`;
  return hasCount(value) ? (
    <div className="ms-1">
      <Link
        to={{
          pathname: RouteConstants.REQUESTS.BASE,
          search: search,
        }}
      >
        <CountInfo color={color}>{fullWord ? `${value} ${dataKey}` : `(${value})`}</CountInfo>
      </Link>
    </div>
  ) : null;
};

type RequestCountsProps = {
  requestCount?: RequestTypeCountDTO;
  category: number;
  gamePk: string | number;
};

const RequestCounts = ({ requestCount, category, gamePk }: RequestCountsProps) => {
  let typeCount = 0;
  if (!!requestCount) {
    if (hasCount(requestCount[PENDING])) {
      typeCount++;
    }
    if (hasCount(requestCount[APPROVED])) {
      typeCount++;
    }
    if (hasCount(requestCount[DENIED])) {
      typeCount++;
    }
  }
  const fullWord = typeCount <= 1;

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <Count dataKey={PENDING} data={requestCount} fullWord={fullWord} category={category} gamePk={gamePk} />
        <Count dataKey={APPROVED} data={requestCount} fullWord={fullWord} category={category} gamePk={gamePk} />
        <Count dataKey={DENIED} data={requestCount} fullWord={fullWord} category={category} gamePk={gamePk} />
      </div>
    </div>
  );
};

type TicketRequestSectionProps = {
  gameDate: string;
  access: EventTimingTypeDTO;
  cutoff: EventTimingDTO;
  toRequest: (type: string) => void;
  blackedOut?: boolean;
  personalAllowed: boolean;
  requestCount: GameRequestCountDTO;
  gamePk: string | number;
};

const TicketRequestSection = ({
  gameDate,
  access,
  cutoff,
  toRequest,
  blackedOut = false,
  personalAllowed,
  requestCount,
  gamePk,
}: TicketRequestSectionProps) => {
  const { isAdmin } = useAuth();
  const personalCutoff = useTimeUntil(gameDate, access, cutoff.personal);
  const businessCutoff = useTimeUntil(gameDate, access, cutoff.business);
  const [businessActive, setBusinessActive] = useState(false);
  const [personalActive, setPersonalActive] = useState(false);

  useEffect(() => {
    setBusinessActive(!blackedOut && (businessCutoff.accessible || isAdmin));
    setPersonalActive(!blackedOut && ((personalCutoff.accessible && personalAllowed) || isAdmin));
  }, [personalCutoff, businessCutoff, isAdmin, personalAllowed]);

  const buttonContainerClasses = "d-flex flex-row flex-sm-column align-items-center align-items-lg-start";
  return (
    <Container>
      <div className="d-flex flex-row justify-content-around w-100">
        <ButtonGroup>
          <div
            className={`d-flex flex-row flex-md-column align-items-center align-items-lg-start ${buttonContainerClasses}`}
          >
            <Button
              variant="primary"
              disabled={!personalActive}
              onClick={() => toRequest(UserAllotmentConstants.PERSONAL)}
            >
              Personal
            </Button>
            <RequestCounts requestCount={requestCount?.personal} gamePk={gamePk} category={1} />
          </div>
          <TimeHolder>
            <TimeRemaining offset={personalCutoff.offset} isPersonal={true} />
          </TimeHolder>
        </ButtonGroup>
        <ButtonGroup>
          <div className={`ms-lg-2 ${buttonContainerClasses}`}>
            <Button
              variant="secondary"
              disabled={!businessActive}
              onClick={() => toRequest(UserAllotmentConstants.BUSINESS)}
            >
              Business
            </Button>
            <RequestCounts requestCount={requestCount?.business} gamePk={gamePk} category={2} />
          </div>
          <TimeHolder>
            <TimeRemaining offset={businessCutoff.offset} isPersonal={false} />
          </TimeHolder>
        </ButtonGroup>
      </div>
    </Container>
  );
};

export default TicketRequestSection;
