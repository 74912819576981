import { EventTimingTypeDTO } from "../types/EventTiming";
import { GameDTO, GameTime } from "../types/Game";
import {
  DATE_FORMATTER_STR_WITH_TIME_AND_ZONE,
  formatDate,
  addHours,
  getMinutesBetween,
  getDifference,
  DateDuration,
  DATE_FORMATTER_STR,
  DATE_FORMATTER_STR_WITH_TIME,
  isAfter,
  StatsGameStatus,
  StatsTimeZone,
} from "best-common-react";
import { DateTime } from "luxon";

export type Offset = DateDuration & {
  text?: string;
};

export type TimeUntil = {
  offset: Offset;
  accessible: boolean;
};

const DEFAULT_TIMEZONE = "America/New_York";

export const convertTz = (tz) => (tz.length === 2 ? tz : tz[0] + tz[tz.length - 1]);

export function useTimezone(date?: string | Date, timezone?: StatsTimeZone, status?: StatsGameStatus): GameTime {
  if (!date && !timezone) return {};
  const isTBD = !!status?.startTimeTBD;
  const dateTime: DateTime = DateTime.fromJSDate(new Date(date));
  const converted: DateTime = isTBD ? dateTime : dateTime.setZone(timezone?.id ?? DEFAULT_TIMEZONE);

  const formattedDate: string = formatDate(converted, "mmm d");
  const day: string = formatDate(converted, "ddd");
  const time: string = !isTBD ? formatDate(converted, "h:MM TT Z") : "TBD";

  return { date: formattedDate, day, time };
}

export function formatSubmittedDate(gameDate: Date | string): string {
  return formatDate(new Date(gameDate), DATE_FORMATTER_STR_WITH_TIME);
}

export function formatGameDate(date?: Date | string, timezone?: StatsTimeZone, tbd?: boolean): string {
  const tz: string | undefined = timezone?.tz ? convertTz(timezone.tz) : undefined;
  if (!!tbd) {
    return formatDate(new Date(date), DATE_FORMATTER_STR) + " - TBD";
  } else {
    if (!!tz) {
      return formatDate(DateTime.fromJSDate(new Date(date)), DATE_FORMATTER_STR_WITH_TIME_AND_ZONE);
    } else {
      return formatDate(new Date(date), DATE_FORMATTER_STR_WITH_TIME_AND_ZONE);
    }
  }
}

export function useCutoffTime(event: GameDTO, useDate = false, date: Date, hoursBefore: number) {
  if (useDate) {
    return formatGameDate(date, event.venue.timeZone, !!event.status ? event.status.startTimeTBD : null);
  }
  return getHoursBeforeText(hoursBefore);
}

export function getHoursBeforeText(hoursBefore: number): string {
  const days = Math.floor(hoursBefore / 24);
  const daysStr = days > 0 ? `${days} day${days > 1 ? "s" : ""} ` : "";
  const hours = hoursBefore % 24;
  const hoursStr = hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""} ` : "";
  return daysStr + hoursStr + "prior to game";
}

const sanitizeDifference = (difference: DateDuration): Offset => {
  const days = Math.trunc(
    (difference?.days ?? 0) +
      (difference?.weeks ?? 0) * 7 +
      (difference?.months ?? 0) * 30 +
      (difference?.years ?? 0) * 365
  );
  return {
    minutes: Math.trunc(difference?.minutes ?? 0),
    hours: Math.trunc(difference?.hours ?? 0),
    days,
  };
};

export function useTimeUntil(
  gameDate: string | Date,
  eventAccess: EventTimingTypeDTO,
  eventCutoff: EventTimingTypeDTO
): TimeUntil {
  const now = new Date();
  const gameDateObj = new Date(gameDate);
  const access: Date | null = eventAccess
    ? eventAccess.useDate
      ? new Date(eventAccess.date)
      : (addHours(gameDateObj, -eventAccess.hoursBefore) as Date)
    : null;
  const cutoff: Date | null = eventCutoff
    ? eventCutoff.useDate
      ? new Date(eventCutoff.date)
      : (addHours(new Date(gameDate), -eventCutoff.hoursBefore) as Date)
    : null;

  if (access && getMinutesBetween(access, now) > 0) {
    return {
      offset: { ...sanitizeDifference(getDifference(now, access)), text: "request tickets in" },
      accessible: false,
    };
  } else if (cutoff && getMinutesBetween(cutoff, now) > 0) {
    return {
      offset: { ...sanitizeDifference(getDifference(now, cutoff)), text: "time remaining" },
      accessible: true,
    };
  } else {
    return {
      offset: {
        minutes: 0,
        days: 0,
        hours: 0,
        text: "time remaining",
      },
      accessible: false,
    };
  }
}

export function isPastHourOfDay(hourOfDay: number): boolean {
  const currentEasternTime: DateTime = DateTime.now().setZone("America/New_York");
  const currentDate = new Date();
  currentDate.setHours(hourOfDay, 0, 0, 0);
  const hourOfDayEastern: DateTime = DateTime.fromJSDate(currentDate).setZone("America/New_York");
  return isAfter(currentEasternTime, hourOfDayEastern);
}
