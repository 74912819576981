import {
  Button,
  Column,
  Hint,
  Input,
  Select,
  useLoading,
  ValueOpt,
  formatDate,
  DATE_FORMATTER_STR,
  Loader,
  Typography,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import {
  forwardRequestDigitalTickets,
  getEventDeliveryMethods,
  resendTickets,
  revokeRequestDigitalTickets,
  sendTicketReminder,
} from "../../../../api/RequesTixApi";
import fulfillmentTypes from "../../../../constants/FulfillmentTypeConstants";
import venueDeliveryTypes from "../../../../constants/VenueDeliveryTypeConstants";
import { BoxOfficeTicketDTO } from "../../../../types/Tickets";
import { VenueDeliveryMethod, VenueDeliveryMethodOptions } from "../../../../types/Venue";
import { fetchDigitalTickets } from "../../../../util/DigitalTicketUtil";
import RevokeTicketsModal from "../../../tickets/RevokeTicketsModal";
import TicketDisplay from "../../../tickets/TicketDisplay";
import TicketSelectionModal from "../../../tickets/TicketSelectionModal";
import { ActionProps } from "./ActionHandler";
import styled from "styled-components";

const TicketHolder = styled.div`
  max-height: 250px;
  overflow-y: scroll;
`;

const getTicketStatus = (value = "") => {
  switch (value ? value.toUpperCase() : "") {
    case "IN_PROGRESS":
      return "tickets not yet accepted";
    case "ACCEPTED":
      return "tickets accepted";
    default:
      return value;
  }
};

const OrderDetailsCompleteAction: React.FC<ActionProps> = ({ game, state, onStateChange, request, save, refresh }) => {
  const { setLoading } = useLoading();
  const [deliveryMethodOptions, setDeliverMethodOptions] = useState<ValueOpt<VenueDeliveryMethodOptions>[]>([]);
  const [ticketSelectionModalOpen, setTicketSelectionModalOpen] = useState<boolean>(false);
  const [recallTicketsModalOpen, setRecallTicketsModalOpen] = useState<boolean>(false);
  const [availableTickets, setAvailableTickets] = useState<BoxOfficeTicketDTO[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<BoxOfficeTicketDTO[]>([]);
  const [loadingTickets, setLoadingTickets] = useState<boolean>(false);

  const resendTicketsToUser = async () => {
    try {
      setLoading(true);
      await resendTickets(request.requestId);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendTicketsReminder = async () => {
    try {
      setLoading(true);
      await sendTicketReminder(state);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchTickets = async (refresh = false) => {
    if (state.deliveryMethodId === venueDeliveryTypes.DIGITAL && game?.teams?.home?.team?.id) {
      try {
        setLoadingTickets(true);
        const tickets: BoxOfficeTicketDTO[] = await fetchDigitalTickets(
          request.gamePk,
          game.teams.home.team.id,
          state.digitalForwardId,
          refresh
        );
        setAvailableTickets(tickets);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingTickets(false);
      }
    }
  };

  const fetchDeliveryMethods = async () => {
    const res: VenueDeliveryMethod[] = await getEventDeliveryMethods(request.gamePk);
    setDeliverMethodOptions(
      res.map((option: VenueDeliveryMethod) => ({
        label: option.deliveryMethod.deliveryMethod,
        value: option.deliveryMethod,
      }))
    );
  };

  const sendDigitalTickets = async (_refresh: boolean) => {
    try {
      await save(false, false);
      setLoading(true);
      await forwardRequestDigitalTickets(state);
      //call refresh again after tickets have sent so we fetch the latest state
      setLoading(true);
      await refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const revokeDigitalTickets = async (_refresh: boolean) => {
    try {
      await save(false, false);
      setLoading(true);
      await revokeRequestDigitalTickets(state);
      setLoading(true);
      //call refresh again after tickets have sent so we fetch the latest state
      await refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchDeliveryMethods();
  }, [request.gamePk]);

  useEffect(() => {
    void fetchTickets(false);
  }, [state.deliveryMethodId, game]);

  useEffect(() => {
    if (state.ticketIds?.length && availableTickets?.length) {
      setSelectedTickets(
        availableTickets
          .filter((t: BoxOfficeTicketDTO) => {
            return (
              (state.ticketIds && state.ticketIds.includes(t.ticketID)) ||
              (state.failedTicketIds && state.failedTicketIds.includes(t.ticketID))
            );
          })
          .map((t: BoxOfficeTicketDTO) => ({
            ...t,
            failed: !!(state.failedTicketIds && state.failedTicketIds.includes(t.ticketID)),
          }))
      );
    } else {
      setSelectedTickets([]);
    }
  }, [availableTickets, state.ticketIds]);

  const ticketsToSelectQty: number =
    (!!state.compQuantity ? state.compQuantity : 0) + (!!state.chargeQuantity ? state.chargeQuantity : 0);
  const selectedTicketLength: number = (state.ticketIds?.length ?? 0) + (state.failedTicketIds?.length ?? 0);

  return (
    !!state.fulfillmentTypeId && (
      <>
        <RevokeTicketsModal
          isOpen={recallTicketsModalOpen}
          close={() => setRecallTicketsModalOpen(false)}
          tickets={selectedTickets}
          onRevoke={() => {
            onStateChange({ ...state, ticketIds: [], ticketSentToRecipient: false });
          }}
          save={revokeDigitalTickets}
          refreshTickets={fetchTickets}
        />
        <TicketSelectionModal
          isOpen={ticketSelectionModalOpen}
          close={() => {
            setTicketSelectionModalOpen(false);
          }}
          tickets={availableTickets}
          ticketQuantity={ticketsToSelectQty}
          selectedTickets={state.ticketIds}
          setSelectedTickets={(values) => {
            onStateChange({ ...state, ticketIds: values, ticketSentToRecipient: values.length === ticketsToSelectQty });
          }}
          save={sendDigitalTickets}
          refreshTickets={fetchTickets}
          loading={loadingTickets}
        />
        <Column width={2}>
          <Select
            id="request-fulfillment-types-dropdown"
            label="Delivery Method"
            value={deliveryMethodOptions.find(
              (option: ValueOpt<VenueDeliveryMethodOptions>) => option.value.id === state.deliveryMethodId
            )}
            options={deliveryMethodOptions}
            onChange={({ value }: ValueOpt<VenueDeliveryMethodOptions>) => {
              onStateChange({ ...state, deliveryMethodId: value.id });
            }}
            placeholder="select..."
            gutterBottom
          />
        </Column>
        <Column width={2}>
          <Input
            type="number"
            id="compQuantity"
            label="Comp Quantity"
            name="comp-quantity-input"
            placeholder="number of tickets"
            onChange={(value) => onStateChange({ ...state, compQuantity: !!value ? parseInt(value) : null })}
            value={state.compQuantity}
            gutterBottom
          />
        </Column>
        {state.fulfillmentTypeId !== fulfillmentTypes.COMP && (
          <Column width={2}>
            <Input
              type="number"
              id="chargeQuantity"
              label="Charge Quantity"
              name="charge-quantity-input"
              placeholder="number of tickets"
              onChange={(value) => onStateChange({ ...state, chargeQuantity: !!value ? parseInt(value) : null })}
              value={state.chargeQuantity}
              gutterBottom
            />
          </Column>
        )}
        {state.deliveryMethodId === venueDeliveryTypes.DIGITAL && (
          <>
            {selectedTicketLength >= ticketsToSelectQty ? (
              <>
                {!!selectedTickets?.length && (
                  <Column width={2}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Button variant="default" onClick={() => setRecallTicketsModalOpen(true)}>
                        Revoke Tickets
                      </Button>
                      <Hint>{getTicketStatus(state.digitalDeliveryStatus)}</Hint>
                    </div>
                    <div>
                      <Button variant="link" onClick={() => setTicketSelectionModalOpen(true)}>
                        Assign Parking Passes
                      </Button>
                    </div>
                    <TicketHolder>
                      {loadingTickets ? (
                        <Loader type="spinner" />
                      ) : (
                        <>
                          {selectedTickets.map((t: BoxOfficeTicketDTO) => (
                            <TicketDisplay data={t} key={t.ticketID} />
                          ))}
                        </>
                      )}
                    </TicketHolder>
                  </Column>
                )}
                <Column width={2}>
                  {!!state.failedTicketIds?.length && (
                    <>
                      <Typography className="mb-1">Some tickets failed to send, attempt to resend them?</Typography>
                      <Button variant="default" className="mb-2" onClick={resendTicketsToUser}>
                        Resend Tickets
                      </Button>
                    </>
                  )}
                  <div className="d-flex align-items-center">
                    <Button variant="default" onClick={sendTicketsReminder}>
                      Send Reminder
                    </Button>
                    {!!state.ticketsDeliveredDate && (
                      <Hint className="ms-2">
                        sent {formatDate(new Date(state.ticketsDeliveredDate), DATE_FORMATTER_STR)}
                      </Hint>
                    )}
                  </div>
                </Column>
              </>
            ) : (
              <Column width={2} className="my-2">
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    variant="link"
                    onClick={() => {
                      setTicketSelectionModalOpen(true);
                    }}
                  >
                    Assign Tickets
                  </Button>
                  <Hint>
                    {selectedTicketLength} of {ticketsToSelectQty} selected
                  </Hint>
                </div>
              </Column>
            )}
          </>
        )}
      </>
    )
  );
};

export default OrderDetailsCompleteAction;
