import { Link, Table, Typography, ToggleButtons, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUserAllotmentCounts } from "../../../api/RequesTixApi";
import RouteConstants from "../../../constants/RouteConstants";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { RequestTypeDesc } from "../../../types/RequestType";
import { SeasonTypeCode } from "../../../types/Season";
import { REGULAR_SEASON, seasonTypesCodes, SPRING_TRAINING } from "../../../util/SeasonUtil";
import { isBusiness } from "../../../util/TicketRequestUtil";

const PendingText = styled.div`
  color: ${(props) => props.theme.palette.themeColorsPrimary};
  font-weight: bold;
  font-style: oblique;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

const sort = (data) =>
  data.sort((a, b) => (a.allotmentType.allotmentTypeId > b.allotmentType.allotmentTypeId ? 1 : -1));

type SeasonSelectProps = {
  year: string;
  userId?: number;
  seasonType: SeasonTypeCode;
  setSeasonType: (value: SeasonTypeCode) => void;
  setAllotments: (value: UserAllotmentCountDTO[]) => void;
  type?: RequestTypeDesc;
  pendingLink?: boolean;
};

const SeasonSelectToggleOpts: ValueOpt<number>[] = [
  {
    value: seasonTypesCodes[SPRING_TRAINING].id,
    label: seasonTypesCodes[SPRING_TRAINING].name,
  },
  {
    value: seasonTypesCodes[REGULAR_SEASON].id,
    label: seasonTypesCodes[REGULAR_SEASON].name,
  },
];

const SeasonSelect: React.FC<SeasonSelectProps> = ({
  year,
  userId,
  seasonType,
  setSeasonType,
  setAllotments,
  type,
  pendingLink = false,
}) => {
  const [allotmentCounts, setAllotmentCounts] = useState([]);
  const [seasonAllotments, setSeasonAllotments] = useState([]);

  const fetchAllotments = async (userId: number, year: number | string) => {
    try {
      const data: UserAllotmentCountDTO[] = await getUserAllotmentCounts(userId, year);
      setAllotmentCounts(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (year && userId) {
      void fetchAllotments(userId, year);
    }
  }, [userId, year]);

  useEffect(() => {
    const sorted: UserAllotmentCountDTO[] = sort(allotmentCounts);
    const filtered: UserAllotmentCountDTO[] = sorted.filter((allotment: UserAllotmentCountDTO) => {
      const correctSeason = seasonType.id === allotment.season.seasonType.seasonTypeId;
      let correctType = true;
      if (type) {
        if (isBusiness(type)) {
          correctType = allotment.allotmentType.allotmentTypeId === 2;
        } else {
          correctType = allotment.allotmentType.allotmentTypeId === 1;
        }
      }
      return correctSeason && correctType;
    });
    setSeasonAllotments(filtered);
    if (setAllotments) {
      setAllotments(sorted);
    }
  }, [allotmentCounts, seasonType]);

  return (
    <Table className="table-sm">
      <Table.Head>
        <Table.Row>
          <Table.Header colSpan={2}>
            <ToggleButtons
              id="season-select-toggle"
              className="w-100"
              buttonClassName="w-100"
              options={SeasonSelectToggleOpts}
              value={seasonType.id}
              size="sm"
              onChange={(value: number) => {
                setSeasonType(value === 1 ? seasonTypesCodes[SPRING_TRAINING] : seasonTypesCodes[REGULAR_SEASON]);
              }}
            />
          </Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {seasonAllotments.map((allotment) => (
          <Table.Row key={allotment.season.seasonId + allotment.allotmentType.allotmentTypeName}>
            <Table.Td>
              <Typography>{allotment.allotmentType.allotmentTypeName}</Typography>
            </Table.Td>
            <Table.Td>
              <div className="d-flex flex-wrap flex-gap-1 align-items-center">
                <Typography>
                  {!allotment.unlimited ? (
                    <>
                      <b>{allotment.remaining}</b> of <b>{allotment.allowed}</b> remaining
                    </>
                  ) : null}
                </Typography>
                {pendingLink ? (
                  <Link
                    to={{
                      pathname: RouteConstants.REQUESTS.BASE,
                      search: `?games=1&status=1,2&category=${allotment.allotmentType.allotmentTypeId}`,
                    }}
                  >
                    <PendingText>{allotment.pending > 0 ? <>({allotment.pending} pending)</> : null}</PendingText>
                  </Link>
                ) : (
                  <PendingText>{allotment.pending > 0 ? <>({allotment.pending} pending)</> : null}</PendingText>
                )}
              </div>
            </Table.Td>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SeasonSelect;
