// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../node_modules/best-common-react/lib/styles/bcr.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add any additional vendor CSS imports here */
`, "",{"version":3,"sources":["webpack://./src/styles/vendors.css"],"names":[],"mappings":"AAEA,+CAA+C","sourcesContent":["@import \"~best-common-react/lib/styles/bcr.css\";\n\n/* Add any additional vendor CSS imports here */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
