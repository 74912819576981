import { BCR, BCRContentContainer, BCRSuspense, Traditional } from "best-common-react";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";
import PermissionDenied from "./AccessDenied";
import AuthenticatedApp from "./AuthenticatedApp";
import { useAuth } from "./contexts/AuthContext";
import UnAuthenticatedApp from "./UnAuthenticatedApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

const AppCheck: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, permissionError, initialized } = useAuth();
  const [hasRedirected, setHasRedirected] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>("");

  useEffect(() => {
    if (!pathname.length) {
      setPathname(location.pathname + location.search);
    }
    if (loggedIn && !hasRedirected && !!pathname.length) {
      navigate(pathname);
      setHasRedirected(true);
    }
  }, [loggedIn, location.pathname]);

  return (
    <BCRContentContainer>
      {initialized ? (
        <>{permissionError ? <PermissionDenied /> : loggedIn ? <AuthenticatedApp /> : <UnAuthenticatedApp />}</>
      ) : (
        <UnAuthenticatedApp />
      )}
    </BCRContentContainer>
  );
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BCR theme={Traditional}>
        <React.Suspense fallback={<BCRSuspense />}>
          <Router>
            <AppCheck />
          </Router>
        </React.Suspense>
      </BCR>
    </QueryClientProvider>
  );
};

export default App;
