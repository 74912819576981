import { ValueOpt } from "best-common-react";
import { Department } from "../types/Department";
import { ReportComplete, RequestCategory, RequestState } from "../types/RequestType";
import { Season } from "../types/Season";

export const All = { label: "All", value: undefined };

export const getStatusOption = (id: string | number, options: ValueOpt<RequestState | object>[]) =>
  //@ts-ignore
  id ? options.find((opt) => opt.value?.requestStateId === parseInt(id as string)) : All;

export const getDepartmentOption = (id: string | number, options: ValueOpt<Department | object>[]) =>
  //@ts-ignore
  id ? options.find((opt) => opt.value?.departmentId === parseInt(id as string)) : All;

export const getCategoryOption = (id: string | number, options: ValueOpt<RequestCategory>[]) =>
  id ? options.find((opt) => opt.value?.requestCategoryId === parseInt(id as string)) : All;

export const getCompleteOption = (id: string | number, options: ValueOpt<ReportComplete>[]) =>
  id ? options.find((opt) => opt.value?.id === parseInt(id as string)) : All;

export const getSeasonOption = (id: string | number, options: ValueOpt<Season>[]) =>
  options.find((opt) => opt.value && opt.value?.seasonId === parseInt(id as string));
