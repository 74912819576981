import {
  ActiveFormatter,
  Button,
  createCssVariableString,
  DataTable,
  DataTableColumn,
  Email,
  Modal,
  SortDirection,
  SortFilters,
  tableSort,
  Typography,
  useLoading,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BoxOfficeTicketDTO } from "../../types/Tickets";
import TicketAgreement from "./TicketAgreement";

type StyleProps = {
  filled: boolean;
};

const TicketCount = styled.span<StyleProps>`
  color: ${(props) =>
    props.filled ? props.theme.palette.coreColorsOnSurfaceGreen : props.theme.palette.coreColorsOnSurfaceRed};
  font-style: italic;
  font-size: 0.8rem;
`;

const TableContainer = styled.div`
  @media (max-height: 1023px) {
    height: 250px;
  }
  @media (min-height: 1024px) {
    height: 400px;
  }
`;

const TicketColumns: DataTableColumn<BoxOfficeTicketDTO>[] = [
  {
    name: "Section",
    key: "section",
    sortable: true,
    width: 125,
  },
  {
    name: "Row",
    key: "row",
    sortable: true,
    width: 100,
  },
  {
    name: "Seat",
    key: "seat",
    sortable: true,
    width: 125,
  },
  {
    name: "Price",
    key: "price",
    sortable: true,
    width: 125,
  },
  {
    name: "Type",
    key: "provider",
    sortable: true,
    width: 100,
  },
  {
    name: "Able To Forward",
    key: "forwardable",
    sortable: true,
    width: 120,
    cellClass: "text-center",
    readonlyFormatter: ActiveFormatter,
  },
];

const SimpleTicketColumns: DataTableColumn<BoxOfficeTicketDTO>[] = [
  {
    name: "Section",
    key: "section",
    sortable: true,
    width: 125,
  },
  {
    name: "Row",
    key: "row",
    sortable: true,
    width: 100,
  },
  {
    name: "Seat",
    key: "seat",
    sortable: true,
    width: 125,
  },
];

const ParkingColumns: DataTableColumn<BoxOfficeTicketDTO>[] = [
  {
    name: "Parking Lot",
    key: "section",
    sortable: true,
    width: 125,
  },
  {
    name: "Price",
    key: "price",
    sortable: true,
    width: 125,
  },
  {
    name: "Type",
    key: "provider",
    sortable: true,
    width: 100,
  },
  {
    name: "Able To Forward",
    key: "forwardable",
    sortable: true,
    width: 120,
    cellClass: "text-center",
    readonlyFormatter: ActiveFormatter,
  },
];

const isParking = (ticket: BoxOfficeTicketDTO) => ticket?.parking || ticket?.row === "Parking";

type TicketSelectionModalProps = {
  isOpen?: boolean;
  close: () => void;
  tickets: BoxOfficeTicketDTO[];
  selectedTickets?: string[];
  ticketQuantity?: number;
  showSimpleColumns?: boolean;
  parkingPassesOnly?: boolean;
  setSelectedTickets: (value: string[]) => void;
  save: (refresh: boolean) => Promise<any>;
  refreshTickets: (refresh: boolean) => any;
  showEmail?: boolean;
  email?: string;
  setEmail?: (value: string) => void;
  setValidEmail?: (value: boolean) => void;
  canSave?: () => boolean;
  todaysTickets?: boolean;
  primaryButtonText?: string;
  loading?: boolean;
};

const TicketSelectionModal: React.FC<TicketSelectionModalProps> = ({
  isOpen = false,
  close,
  tickets = [],
  ticketQuantity = 0,
  selectedTickets = [],
  setSelectedTickets,
  showSimpleColumns = true,
  parkingPassesOnly = false,
  save,
  refreshTickets,
  showEmail = false,
  email,
  setEmail,
  setValidEmail,
  canSave,
  todaysTickets = false,
  primaryButtonText = "Send Tickets",
  loading = false,
}) => {
  const { setLoading } = useLoading();
  const [ticketsSortFilters, setTicketsSortFilters] = useState<SortFilters<BoxOfficeTicketDTO>>({
    key: "section",
    direction: "ASC",
  });
  const [parkingSortFilters, setParkingSortFilters] = useState<SortFilters<BoxOfficeTicketDTO>>({
    key: "price",
    direction: "ASC",
  });
  const [displayTickets, setDisplayTickets] = useState<BoxOfficeTicketDTO[]>([]);
  const [parkingTickets, setParkingTickets] = useState<BoxOfficeTicketDTO[]>([]);
  const [showParkingPasses, setShowParkingPasses] = useState<boolean>(false);
  const [agreement, setAgreed] = useState<boolean>(false);

  const sendTickets = () => {
    setLoading(true);
    save(false).then(() => {
      close();
      setLoading(false);
      setTimeout(() => {
        refreshTickets(true);
      }, 1000);
    });
  };

  const refreshLatestTickets = () => {
    refreshTickets(true);
  };

  const onClose = () => {
    setSelectedTickets([]);
    close();
  };

  const onTicketSort = (key: keyof BoxOfficeTicketDTO, direction: SortDirection) =>
    setTicketsSortFilters({ key, direction });

  const onParkingSort = (key: keyof BoxOfficeTicketDTO, direction: SortDirection) =>
    setParkingSortFilters({ key, direction });

  const toggleParkingPasses = () => {
    setShowParkingPasses(!showParkingPasses);
  };

  const isSelected = (rowIndex: number, isParking: boolean): boolean => {
    const value: BoxOfficeTicketDTO = isParking ? parkingTickets[rowIndex] : displayTickets[rowIndex];
    return selectedTickets.includes(value?.ticketID);
  };

  const isSelectionDisabled = (rowIndex: number, isParking: boolean): boolean => {
    const value: BoxOfficeTicketDTO = isParking ? parkingTickets[rowIndex] : displayTickets[rowIndex];
    return isValueDisabled(value);
  };

  const isValueDisabled = (value: BoxOfficeTicketDTO): boolean => {
    if (selectedTickets.length >= ticketQuantity) {
      return !selectedTickets.includes(value?.ticketID);
    } else {
      return !value?.forwardable;
    }
  };

  const onSelectionChange = (rowIndexes: number[], selected: boolean, isParking: boolean) => {
    const ticketArray: BoxOfficeTicketDTO[] = (isParking ? parkingTickets : displayTickets) ?? [];
    const values: BoxOfficeTicketDTO[] = rowIndexes.map((index: number) => ticketArray[index]);
    const newIds: string[] = values.map((r: BoxOfficeTicketDTO) => r?.ticketID).filter((id) => id !== undefined);
    if (selected) {
      setSelectedTickets([...selectedTickets, ...newIds]);
    } else {
      setSelectedTickets(selectedTickets.filter((st) => !newIds.includes(st)));
    }
  };

  useEffect(() => {
    if (!!tickets) {
      const tempTickets: BoxOfficeTicketDTO[] = tickets
        .filter((t: BoxOfficeTicketDTO) => !isParking(t))
        .map((t: BoxOfficeTicketDTO) => ({
          ...t,
          id: t.ticketID,
        }));
      setDisplayTickets(tableSort(tempTickets, ticketsSortFilters));
    }
  }, [tickets, ticketsSortFilters]);

  useEffect(() => {
    if (!!tickets && showParkingPasses) {
      const parking: BoxOfficeTicketDTO[] = tickets
        .filter((t: BoxOfficeTicketDTO) => isParking(t))
        .map((t: BoxOfficeTicketDTO) => ({
          ...t,
          id: t.ticketID,
        }));
      setParkingTickets(parking);
    }
  }, [tickets, parkingSortFilters, showParkingPasses]);

  useEffect(() => {
    if (parkingPassesOnly) {
      setShowParkingPasses(true);
    }
  }, [parkingPassesOnly]);

  const ticketObjs: BoxOfficeTicketDTO[] = !!selectedTickets
    ? tickets.filter((t: BoxOfficeTicketDTO) => selectedTickets.includes(t?.ticketID))
    : [];
  const ticketCount: number = ticketObjs.filter((t) => !isParking(t)).length;
  const parkingCount: number = ticketObjs.filter((t) => isParking(t)).length;

  const validTodaysTickets: boolean = todaysTickets ? !!agreement : true;
  const localCanSave = (): boolean => selectedTickets.length < ticketQuantity;
  const canSaveVal = !!canSave ? !canSave() : localCanSave();
  const isSaveDisabled: boolean = canSaveVal || !validTodaysTickets;

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header close={onClose}>Available Tickets</Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
          <Typography>Please select {ticketQuantity} tickets</Typography>
          <Button variant="link" onClick={refreshLatestTickets}>
            Refresh All Tickets
          </Button>
        </div>
        {showEmail && (
          <Email
            id="email"
            value={email}
            onChange={setEmail}
            setValidEmail={setValidEmail}
            gutterBottom
            placeholder="Enter your email address associated with the Ballpark App (@mlb.com email address preferred)"
          />
        )}
        {!parkingPassesOnly && (
          <>
            <TableContainer>
              <DataTable
                columns={showSimpleColumns ? SimpleTicketColumns : TicketColumns}
                data={displayTickets}
                sortColumn={ticketsSortFilters.key}
                sortDirection={ticketsSortFilters.direction as SortDirection}
                sortFunction={onTicketSort}
                rowDisabledKey="disabled"
                statusTextOverride={(selected, total) =>
                  !!selected ? `${selected} of ${total} Tickets Selected` : `${total} Tickets`
                }
                rowStylesGetter={(_, value: BoxOfficeTicketDTO) => {
                  if (isValueDisabled(value)) {
                    return { background: createCssVariableString("surfaceColorsSurfaceContainerHighest") };
                  } else {
                    return {};
                  }
                }}
                rowSelection={{
                  isSelected: (index: number) => isSelected(index, false),
                  isSelectionDisabled: (index: number) => isSelectionDisabled(index, false),
                  onSelectionChange: (rowIndexes: number[], selected: boolean) =>
                    onSelectionChange(rowIndexes, selected, false),
                }}
                selectAll={false}
                tableHeights={{
                  parentHeight: true,
                }}
                loading={loading}
              />
            </TableContainer>
            <div className="d-flex w-100 justify-content-end my-3">
              <TicketCount filled={ticketCount === ticketQuantity}>
                {ticketCount} of {ticketQuantity} selected
              </TicketCount>
            </div>
          </>
        )}
        {parkingTickets.length ? (
          <>
            <div className="d-flex justify-content-end mt-2">
              <Button variant="default" onClick={toggleParkingPasses}>
                {showParkingPasses ? "Hide Parking Passes" : "Show Parking Passes"}
              </Button>
            </div>
            {showParkingPasses && (
              <div className="mt-2">
                <div className="d-flex justify-content-end my-2">
                  <TicketCount filled={parkingCount > 0}>{parkingCount} selected</TicketCount>
                </div>
                <TableContainer>
                  <DataTable
                    columns={ParkingColumns}
                    data={parkingTickets}
                    sortColumn={parkingSortFilters.key}
                    sortDirection={parkingSortFilters.direction as SortDirection}
                    sortFunction={onParkingSort}
                    rowDisabledKey="disabled"
                    rowSelection={{
                      isSelected: (index: number) => isSelected(index, true),
                      isSelectionDisabled: (index: number) => isSelectionDisabled(index, true),
                      onSelectionChange: (rowIndexes: number[], selected: boolean) =>
                        onSelectionChange(rowIndexes, selected, true),
                    }}
                    selectAll={false}
                    tableHeights={{
                      parentHeight: true,
                    }}
                    loading={loading}
                  />
                </TableContainer>
              </div>
            )}
          </>
        ) : null}
        {!!todaysTickets && <TicketAgreement setAgreed={setAgreed} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={isSaveDisabled} className="me-2" onClick={sendTickets}>
          {primaryButtonText}
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketSelectionModal;
