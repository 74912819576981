import "./styles/vendors.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { EnvProvider } from "./contexts/EnvContext";
import WrapperProvider from "./contexts/WrapperProvider";

const container = document.getElementById("requestix-app");
const root = createRoot(container);

root.render(
  <EnvProvider>
    <WrapperProvider>
      <App />
    </WrapperProvider>
  </EnvProvider>
);
